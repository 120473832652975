.page-template-contact {
  .page-title {
    font-size: rem-calc(40);
    text-transform: uppercase;
  }
  .contact-data {
    p {
      margin-bottom: 0;
    }
  }
}