
.search-site {
  padding: rem-calc(10) 0;
  .input-group-button {
    .button {
      width: rem-calc(14);
      background-image: url('../img/Search2x.svg');
      background-size: contain;
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      margin-right: rem-calc(8);
      border: none;
      &:hover {
        background-color: transparent;
        color: $primary-color;
      }

      .svg {
        background-color: transparent;
      }
    }
  }

  .input-group {
    border-bottom: 1px solid transparent;
    @include ba-transition();
    &:after {
      display: table;
      content: ' ';
      clear: both;
    }

    &:hover {
      border-bottom: 1px solid $dark-gray;
    }
    @include breakpoint(small down) {
      float: none;
      margin: 0 auto;
    }

  }
  .input-group-field {
    background-color: transparent;
    color: $black;
    border: none;
    box-shadow: none;
    &.placeholder-shift-right {
      @include placeholder {
        color: $black;
        font-size: rem-calc(14);
        font-weight: 200;
      }
    }
  }
}

.search-site .input-group .input-group-field:focus .search-site .input-group.input-group {
  border-bottom: 1px solid $black;
}
