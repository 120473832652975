/* Navigation breakpoints */

.desktop-menu, .top-bar-left {
  @include show-for(medium);
}

#mobile-menu, .title-bar {
  @include hide-for(medium);
}

/* Mobile menu */

.off-canvas {
  > ul.menu {
    height: 100vh;
    padding: 1rem;
  }
}

.title-bar-title {
  a {
    font-size: 1rem;
    color: #B9B9B9;
  }
}

/* Tablet and desktop menu */
.top-bar {

  .menu {
    .menu-item {
      a {
        color: $light-text-color;
        padding-top: 0;
        padding-bottom: 0;
        font-weight: 300;
        font-size: rem-calc(18);
        line-height: 1;

        &:hover:not(.button) {
          color: $black;
          border-bottom: $light-text-color solid 1px;
        }
      }
      &.active {
        & > a {
          color: $black;
          border-bottom: $black solid 1px;
        }
      }
    }

  }

  .menu > li:not(.menu-text) {
    padding: rem-calc(14 15);
    & > a {
      padding: rem-calc(6 0);
      border-bottom: 1px solid transparent;
      @include ba-transition();
    }
  }
  .menu li:not(:last-child) {
    //border-right: 1px solid #4e4e4e;
  }

  .dropdown.menu .submenu {
    background-color: $white;
  }

  .dropdown.menu .has-submenu.is-down-arrow a {
    padding-right: 1rem;
  }
  .dropdown.menu .has-submenu.is-down-arrow > a::after {
    border: 0;
  }

  .dropdown.menu:first-child >
  li.is-dropdown-submenu-parent >
  a::after {
    display: none;
  }

}

/* Basic styles for post navigation. Nicolas Gallagher's "Micro clearfix" https://css-tricks.com/snippets/css/clear-fix/ */
.post-navigation:before,
.post-navigation:after {
  content: "";
  display: table;
}

.post-navigation:after {
  clear: both;
}

/* Text meant only for screen readers. Addresses part of theme-check issue #840. https://github.com/Automattic/_s/blob/master/style.css */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

.nav-previous {
  float: left;
}

.nav-next {
  float: right;
}
