
.full-width-slider {
  //slick
  .slick-dots {
    li {
      button {
        &:before {
          font-size: rem-calc(50);
        }
      }
    }

  }
  //end slick

  .slide {
    min-height: 50vh;
    position: relative;

    @include breakpoint(small down) {
      min-height: 60vh;
    }
    &__caption {
      width: 100%;
      text-align: center;

      @include breakpoint(small down) {
        top: 50%;
      }

      h2, h1 {
        font-size: rem-calc(60);
        line-height: rem-calc(72);
        margin-bottom: 0;
        @include breakpoint(small down){
          font-size: rem-calc(30);
        }
      }
      p {
        font-size:rem-calc(12);
        font-weight:500;
        line-height:rem-calc(14);
        margin-bottom: rem-calc(20);
      }

    }
  }

}
