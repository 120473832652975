.gform_wrapper.gform_wrapper {
  h3.gform_title {
    font-size: rem-calc(45);
    font-weight: 200;
    line-height: rem-calc(50);
  }

  .top_label {
    .gfield_label {
      font-size: rem-calc(14);
      line-height: rem-calc(20);
      opacity: 0.5;
      font-weight: normal;
    }
  }

  .ginput_complex{
    label{
      font-size: rem-calc(14);
      line-height: rem-calc(20);
      opacity: 0.5;
      font-weight: normal;
    }
  }

  .validation_error{
    display: none;
  }

  .gform_footer input.button,
  .gform_wrapper .gform_footer input[type=submit],
  .gform_wrapper .gform_page_footer input.button,
  .gform_wrapper .gform_page_footer input[type=submit] {
    background: $black;
    font-size: rem-calc(16);
    line-height: 1.19;
    text-align: center;
    font-weight: bold;
    min-width: rem-calc(160);
    padding: rem-calc(9 17);
    text-transform: capitalize;
    @include ba-transition();

    @include breakpoint(small down) {
      max-width: 50%;
    }
    &:hover {
      opacity: 0.8;
    }
  }

  &.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
    @include breakpoint(small down) {
      line-height: 1.19;
    }
  }

  [type='text'],
  [type='password'],
  [type='date'],
  [type='datetime'],
  [type='datetime-local']
   [type='month'],
  [type='week'],
  [type='email'],
  [type='number'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='url'],
  [type='color'],
  textarea {
    padding: rem-calc(7);
    box-shadow: none;
    font-size: rem-calc(18);
    font-weight: normal;
    line-height: rem-calc(24);
    color: $extra-dark-gray;

    @include placeholder {
      font-size: rem-calc(18);
      font-weight: normal;
      line-height: rem-calc(24);
      color: $extra-dark-gray;
      opacity: 0.49;
    }

    &:focus {
      border: 1px solid $extra-dark-gray;
    }
  }

  textarea {

  }

  [type='text'],
  [type='password'],
  [type='date'],
  [type='datetime'],
  [type='datetime-local']
   [type='month'],
  [type='week'],
  [type='email'],
  [type='number'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='url'],
  [type='color'] {
    @include placeholder {
      transition: text-indent 300ms;
    }

    &:focus {
      @include placeholder {
        text-indent: 1000px;
      }
    }
  }
}