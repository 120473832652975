.header {
  padding-top: rem-calc(30);
  .top {
    padding: rem-calc(20 0);
    .contacts {
      padding: rem-calc(10) 0;
      font-size: rem-calc(14);
      a {
        display: block;
        color: $light-text-color;
        @include breakpoint(small down) {
          text-align: center;
        }
      }
    }
    .logo {
      display: block;
      margin: 10px auto;
      max-width: rem-calc(220);
    }
  }

  .top-banner {
    padding: rem-calc(10 0);
    color: $white;
  }
  .search-site {
    .input-group {
      max-width: rem-calc(150);
      float: right;
    }
  }
}
