.vertical.tabs {
  border: none;
  .tabs-title {
    &.is-active > a:focus, & > a[aria-selected='true'] {
      font-size: rem-calc(17);
      color: $black;
      background: none;
    }
    a {
      background: none;
      padding: rem-calc(10 0);
      font-size: rem-calc(17);
      color: $very-light-text-color;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

.tabs-content{
  border:none;
  .tabs-panel{
    padding:0;
  }
}


