.single-barrister,
.single-person {
  .post-title {
    font-weight: 300;
    font-size: rem-calc(24);
    margin-bottom: rem-calc(40);
    @include breakpoint(small down) {
      margin-bottom: rem-calc(20);
    }
  }

  .standfirst {
    font-size: rem-calc(24);
    color: $black;
  }
  .content {
    font-size: rem-calc(20);
    color: $main-text-color;
  }
  .profiles-sections {
    &__item{
      padding:rem-calc(15 0);
    }
    .accordion-item{
      padding:rem-calc(15 0);
      &.is-active{

      }
    }
    .profiles-sections-title,
    .accordion .accordion-item .accordion-title {
      font-size: rem-calc(24);
      color: $black;
      @include ba-transition();
    }
    .accordion .accordion-item .accordion-title {
      &:hover {
        color: $primary-color;
      }
    }
    p {
      margin-bottom: 0;
    }
    .end {
      margin-bottom: rem-calc(30);
    }
    ul {
      padding-left: 0;
      margin-left: 0;
      li {
        list-style-type: none;
        a {
          .title {
            @include ba-transition();
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}

.single-person {
  .post-title {
    margin-bottom: rem-calc(0);
  }
}

.person-sidebar {
  font-size: rem-calc(14);
  .year, .share, .job-title {
    margin-bottom: 0;
  }
  .job-title {
    font-size: rem-calc(18);
  }
  a {
    color: $black;
  }
  ul {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: rem-calc(5);
    li {
      list-style-type: none;
    }
  }
  .social-icon {
    margin: rem-calc(5);
  }

}

.save-as-pdf,
.save-as-vcard {
  .button {
    background: none;
    color: $black;
    font-size: rem-calc(14);
    padding-left: 0;
    padding-bottom: 0;
    border-bottom: 1px solid $black;
  }
}



