//.page-template-fqas {
//  .accordion {
//    font-size: rem-calc(17);
//    .accordion-item {
//      a.accordion-title {
//        padding-left: rem-calc(25);
//        text-transform: uppercase;
//        font-size: rem-calc(17);
//        &:before {
//          right: auto;
//          left: rem-calc(5);
//          background-image: url('../img/iconfinder-icon.svg');
//        }
//
//
//      }
//      &.is-active > a.accordion-title {
//        &:before {
//          transform: translate(-50%, 0%) rotate(-180deg);
//          background-image: url('../img/iconfinder-icon-minus.svg');
//
//        }
//      }
//      .accordion-content {
//        padding-left: rem-calc(25);
//        font-size: rem-calc(20);
//      }
//    }
//  }
//}
.page-template-fqas {
  .accordion {
    .profiles-sections-title,
    .accordion-item .accordion-title {
      font-size: rem-calc(24);
      color: $black;
      @include ba-transition();
    }
  }
}