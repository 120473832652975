@charset 'utf-8';
/*
  Foundation for Sites components:
  Import the building blocks you need for your project.
  Reducing the number of imported components will optimize page speed.
  ---------------------------------------------------------------------
  The Foundation framework is built by Zurb
  Licensed under MIT Open Source
*/
//  Settings
@import "settings";
// Foundation settings file.
// Foundation mixins & browser resets
@import 'foundation';
// WP overrides
@import "global/wp-admin";
// Fix issues with wp-admin bar positioning
@import "global/wp-overrides"; // Override the default WordPress styling for some elements
@import "hover.css";
// Third-party libraries
// @import '../components/fontawesome/scss/font-awesome';

//@import 'motion-ui';
// Foundation global styles
@include foundation-global-styles;

// Individual foundation components
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
 @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
@include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
// @include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
 @include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

// Motion UI
// @include motion-ui-transitions;
// @include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// @import "map";

@import "main";

// Modules
@import "modules/navigation";
@import "modules/footer";
@import "modules/header";
@import "modules/sidebar";
@import "modules/editor-style";
@import "modules/search-site";
@import "modules/tabs";
@import "modules/_accordion";
@import "modules/_pdf";

// Components
@import "components/buttons";
@import "components/featured-image";
@import "components/dividers";
@import "components/links";
@import "components/slick";
@import "components/slick-theme";
@import "components/map";

// Parts
@import "parts/social-network";
@import "parts/banner";
@import "parts/full-width-slider";
@import "parts/gravity-form";
@import "parts/join-mailing-form";
@import "parts/posts";
@import "parts/post-prev";
@import "parts/people-prev-posts";

// Templates
@import "templates/front";
//@import "templates/kitchen-sink";
@import "templates/page-default";
@import "templates/page-sidebar-left";
@import "templates/page-full-width";
@import "templates/single-post";
@import "templates/single-person";
@import "templates/people-archive";
@import "templates/contact";
@import "templates/faqs";
@import "templates/search";
