.sidebar {
  @include breakpoint(small) {
    @include grid-column(12);
  }

  @include breakpoint(medium) {
    @include grid-column(4);
    margin-top: 1rem;
  }

  @include breakpoint(large) {
    @include grid-column(3);
    margin-top: 1rem;
  }

  /* sidebar left & right  */
  &.sidebar-left,
  &.sidebar-right {
    @include breakpoint(medium) {
      @include grid-column(3);
      margin-top: 1rem;
    }
  }
  /* end sidebar left & right   */

  /* sidebar left   */
  &.sidebar-left {
    .submenu {
      font-size: rem-calc(17);
      text-transform: uppercase;
      .sub-menu {
        padding:rem-calc(5 0 25 0);
        text-transform: capitalize;
        font-size: rem-calc(14);
        margin-left:rem-calc(15);
        li {
          &.current-menu-item.active {
            & > a {
              font-size: rem-calc(14);
              color: $black;
            }
          }
        }
      }
      li {
        &.title {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        &.current-menu-item,
        &.current-event-ancestor,
        &.title {
          & > a {
            font-size: rem-calc(17);
            color: $black;
          }
        }
        a {
          color: $light-text-color;
          &:hover {
            color: $primary-color;
          }
        }

      }
    }

    .logotype {
      margin: rem-calc(25 5);
    }
  }

  /* end sidebar left   */

  /* sidebar right   */
  &.sidebar-right {

  }
  /* end sidebar right   */
}

.page-child {
  .sidebar {
    &.sidebar-left {
      .submenu {
        font-size: rem-calc(17);
        li {
          &.current-menu-item.active {
            & > a {
              //font-size: rem-calc(17);
              color: $black;
            }
          }
          &.title {
            a {
              color: $very-light-text-color;
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

.container.news {
  .sidebar {
    &.sidebar-left {
      .submenu {
        li.menu-item-4152 {
          a {
            color: $black;
          }

        }
      }
    }
  }
}

.container.articles {
  .sidebar {
    &.sidebar-left {
      .submenu {
        li.menu-item-4153 {
          a {
            color: $black;
          }

        }
      }
    }
  }
  .post-preview.post-preview {
    border-left: 5px solid $alert-color;
  }
}

.container.publications {
  .sidebar {
    &.sidebar-left {
      .submenu {
        li.menu-item-4154 {
          a {
            color: $black;
          }
        }
      }
    }
  }
  .post-preview.post-preview {
    border-left: 5px solid $primary-color;
  }
}

.container.judgments-and-decisions {
  .sidebar {
    &.sidebar-left {
      .submenu {
        li.menu-item-4151 {
          a {
            color: $black;
          }
        }
      }
    }
  }
  .post-preview.post-preview {
    border-left: 5px solid $primary-color;
  }
}

.container.webinars {
  .sidebar {
    &.sidebar-left {
      .submenu {
        li.menu-item-4156 {
          a {
            color: $black;
          }
        }
      }
    }
  }
  .post-preview.post-preview {
    border-left: 5px solid $primary-color;
  }
}

