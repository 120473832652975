.post-preview {
  background-color: $light-gray;
  padding: rem-calc(20);
  margin-bottom: rem-calc(20);
  font-size: rem-calc(18);
  @include ba-transition();
  color: $black;
  &.prev-post-search {
    background: none;
  }
  &:hover {
    background-color: $light-gray*0.95;
  }
  .date,
  .event-categories li {
    font-size: rem-calc(14);
    font-weight: bold;
    margin-bottom: rem-calc(15);
    color: $black;
    a {
      color: $black;
    }
  }

  .event-categories {
    margin-left: 0;
    padding-left: 0;
    li {
      list-style-type: none;
    }
  }

  .excerpt,
  .title {
    color: $black;
    a {
      color: $black;
    }
  }
  .author {
    font-size: rem-calc(12);
    line-height: rem-calc(16);
  }
}

.areas-excerpt {
  p {
    margin-bottom: 0;
  }
}

.areas,
.page-template-areas {
  .post-preview {
    margin-bottom: rem-calc(30);
    .title {
      text-transform: uppercase;
      font-weight: normal;
      font-size: rem-calc(15);
      margin-bottom: 0;
    }
  }
}

.page-template-home {
  .areas {
    .post-preview {
      margin-bottom: rem-calc(5);
    }
  }
}

.latest-events {
  .date {
    margin-bottom: 0;
  }
}

.page-template-category-news,
.page-template-category-publications,
.page-template-category-articles,
.single-post {
  .post-preview {
    p {
      margin-bottom: 0;
    }
    .excerpt {
      p {
        margin-bottom: 0;
      }
    }
    a {
      .date {
        color: $main-text-color;
      }
    }
  }
}