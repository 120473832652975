.footer {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    &.menu > li > a {
      padding: rem-calc(11);
    }
  }

  .copyright {
    border-top: 1px solid $medium-gray;
    padding: rem-calc(18 0);
    color: $very-light-text-color;
    font-size: rem-calc(12);
    p {
      padding: rem-calc(11 0);
      margin-bottom: 0;
      line-height: 1;
      &.author {
        a {
          color: $black;
        }
      }
    }
    a {
      color: $very-light-text-color;
    }

  }

}
