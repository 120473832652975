.social-icon {
  @include breakpoint(small down) {
    display: flex;
    justify-content: center;
  }
  .social {
    margin: 15px 0 5px 0;
    &__icon {
      height: rem-calc(23);
      width: rem-calc(23);
      position: relative;
      line-height: 1;
      margin: rem-calc(9 3);
      font-size: rem-calc(9);
      border-radius: rem-calc(50);
      border: 1px solid $primary-color;
      display: inline-block;
      img,
      .icon-svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .icon-svg {
        color: $primary-color;
        svg {
          font-size: rem-calc(10);
          fill: currentColor;
        }
      }

      &:hover {
        background-color: $primary-color;
        opacity: 0.8;
        .icon-svg {
          color: $white;
          svg {
            fill: currentColor;
          }
        }
      }
    }
  }
}

