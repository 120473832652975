.post.people-post {
  .prev-content {

    padding: rem-calc(15 0);
    &__image{
      position: relative;
    }
    .dimming-effect:after {
      background-color: rgba($primary-color,0.6);
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      content: '';
      z-index: 0;
      opacity:0;
      @include ba-transition();
    }
    &__meta {
      .person-title {
        font-weight: 500;
      }
      .job-title {
        margin-bottom: 0;
      }

      .person-year,
      .person-title,
      .job-title{
        font-size: rem-calc(18);
        color: $black;
        @include ba-transition();
        margin-bottom: 0;
      }
      .person-data{
        font-size:rem-calc(14);
        p{
          margin-bottom: 0;
        }
        a{
          color: $main-text-color;
        }
      }
    }

  }
  &:hover {
    .prev-content__meta {
      .person-year,
      .person-title,
      .job-title,
      .person-data{
        color: $primary-color;
      }
    }
    .dimming-effect:after {
      opacity:1;
    }
  }
}