/*** Overall ***/

svg {
  display: inline-block;
  vertical-align: middle;
  @include ba-transition('fill, stroke');
}

.excerpt {
  span {
    font-size: rem-calc(14);
  }
}

a,
[type="submit"],
[type="button"],
button {
  @include ba-transition;
}

.centered-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.valign-middle-large {
  @include breakpoint(large up) {
    width: 100%;
    display: table;
    .columns {
      display: table-cell;
      vertical-align: middle;
    }
    .columns,
    [class*="column"] + [class*="column"]:last-child {
      float: none;
    }
  }

}

.read-more {
  color: $primary-color;
}

.small-text-centered {
  @include breakpoint(small down) {
    text-align: center;
  }
}

.text-centered {
  text-align: center;
}

.bl-primary-color {
  border-left: 5px solid $primary-color;
}

.bl-success-color {
  border-left: 5px solid $success-color;
}

.bl-secondary-color {
  border-left: 5px solid $secondary-color;
}

.bl-warning-color {
  border-left: 5px solid $warning-color;
}

.bl-alert-color {
  border-left: 5px solid $alert-color;
}

.standfirst {
  font-size: rem-calc(24);
  color: $black;
}

.page {
  .page-title {
    font-size: rem-calc(40);
    text-transform: uppercase;
  }
}

.page-template-category-news,
.page-template-category-publications,
.page-template-category-articles,
.page-template-people,
.single-post,
.single {
  .page-title {
    font-size: rem-calc(40);
    text-transform: uppercase;
  }
  .date,
  .like-date {
    font-size: rem-calc(14);
    font-weight: bold;
    color: $black;
    margin-bottom: rem-calc(15);
  }
  .related-barristers {
    padding: rem-calc(50 0);
  }
}

.attachment-prev-person.attachment-prev-person {
  margin: 0;
}

.is-dropdown-submenu.is-dropdown-submenu {
  z-index: 10;
}

.pagination {
  text-align: center;
}

.shiftnav.shiftnav-skin-light ul.shiftnav-menu li.menu-item .shiftnav-submenu-activation {
  display: flex;
  align-items: center;
}

#searchsubmit {
  outline: none;
}

.show {
  display: block;
}

.like-h1 {
  font-size: rem-calc(60);
}

@include breakpoint(small down) {
  .like-h1 {
    font-size: rem-calc(60);
  }
}

//child links boxes
.child-links-boxes {
  .submenu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .menu-item {
      min-width:25%;
      @include breakpoint(medium down){
        min-width:40%;
      }
      @include breakpoint(small down){
        min-width:90%;
      }
      padding: rem-calc(20);
      margin: rem-calc(5 30);
      border-left: 5px solid $primary-color;
      background-color: $light-gray;
      @include ba-transition();
      a{
        color: $black;
      }
      &:hover{
        background-color: $light-gray*0.95;
      }
    }
  }
}

// end child links boxes

/*** End  Overall ***/

/*** General classes ***/

.btn {
  display: inline-block;
  margin: rem-calc(12);
  color: $white;
  border: 1px solid $white;
  padding: rem-calc(15 20);
  text-transform: uppercase;
  background-color: transparent;
  @include breakpoint(small down) {
    padding: rem-calc(10 15);
  }
  &:hover {
    color: $black;
  }
  &.hvr-sweep-to-bottom:before {
    background: $white;
  }
}

.float-right {
  float: right;
}

.bg-cover-no-repeat {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.dimming-effect:after {
  background-color: rgba(9, 20, 48, 0.45);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  content: '';
  z-index: 0;
}

//flex
.jc-space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.jc-flex-start {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.jc-center-medium {
  @include breakpoint(medium down) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

//end flex

.absolute-center {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.wp-template {
  position: fixed;
  left: 10px;
  top: 100px;
  z-index: 11;
  font-weight: bold;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background: black;
  color: white;
}

.wp-template span {
  display: block;
  font-size: 0.7rem;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

/*** End general classes ***/

/*** Typography ***/
.dark {
  h1, h2, h3, h4, h5, h6, p, ul li, q {
    color: $white;
  }
}

.light {
  h1, h2, h3, h4, h5, h6, p, ul li {
    color: $black;
  }
}

.container {
  padding: $global-page-padding;
}

.page-template-adr {
  .container {
    padding: $global-page-padding-btm;
  }
}

.crumbs {
  padding: rem-calc(7 0);
}

/*** End typography ***/

/* Placeholder*/
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}

.placeholder-shift-right {
  @include placeholder {
    text-indent: 0;
    color: white;
    transition: text-indent 300ms;
  }

  &:focus {
    @include placeholder {
      text-indent: 800px;
    }

  }
}

/*End  Placeholder*/

/* Hovers */

.grow-wrapper {
  overflow: hidden;
  .grow {
    img {
      width: 100%;
      transition: all 1s ease;
    }

    &:hover {
      img {
        transform: scale(1.2);
        width: 100%;
      }
    }
  }

}

.rotate:hover {
  display: inline-block;
  transform: rotate(360deg);
}

@-webkit-keyframes swing {
  15% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  30% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  50% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  65% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes swing {
  15% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  30% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  50% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  65% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.swing:hover {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.swing-icon:hover i {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Border line */
@mixin box-sizing($val: border-box) {
  box-sizing: $val;
}

* {
  @include box-sizing;
}

.border-line-hover {
  position: relative;
  overflow: hidden;

  &:before {
    @include box-sizing;
    transform: translateX(100%);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 99.5%;
    height: 3px;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    z-index: 1;
  }

  &:after {
    @include box-sizing;
    transform: translateX(-100%);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 99.5%;
    height: 2px;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    z-index: 1;
  }

  &:hover {
    color: inherit;
    text-decoration: none;

    &:before {
      transition: .3s transform linear, 0.1s height linear .35s;
      transform: translateX(0);
      height: 100%;
      border-color: $primary-color;
    }
    &:after {
      transition: .3s transform linear, 0.1s height linear .35s;
      transform: translateX(0);
      height: 100%;
      border-color: $primary-color;
    }
  }

}

/* End border line */
/* End Hovers */


