.page-template-barristers,
.page-template-clerks,
.page-template-people{
  .container{
    padding-top: rem-calc(25)
  }
  .page-title {
    font-size: rem-calc(40);
    padding:rem-calc(15 0 25 0);
  }

  .term-title{
    line-height:rem-calc(32);
    padding:rem-calc(35 0 10 0);
    font-weight: normal;
    border-bottom: 2px solid $warning-color;
  }
}
.page-template-clerks {
  .container{
    padding:$global-page-padding-btm;
  }
  a{
    color: $main-text-color;
  }
}