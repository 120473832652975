.posts{
  .prev-content {
    padding-bottom: rem-calc(48);
    z-index: 0;
    position: relative;
    @include breakpoint(small down) {
      padding-bottom: rem-calc(18);
    }

    &.attorney{
      margin-bottom: rem-calc(24);
      margin-top: rem-calc(24);
      z-index: 0;
      padding: rem-calc(10);
      position: relative;
      @include breakpoint(small down) {
        margin-bottom: rem-calc(9);
        margin-top: rem-calc(9);
      }
    }
    &__title {
      font-weight: 300;
      a {
        color: $black;
      }
    }

    &__image, &__meta {
      z-index: 10;
    }

    &__meta {
      border-bottom: 1px solid #DEE1E4;
      font-size: rem-calc(18);
      font-weight: bold;
      padding: rem-calc(13 3 0 3);
      p {
        margin-bottom: 0;
      }
      .category, .date {
        display: block;
      }
    }

    &__entry-content {
      padding: rem-calc(20 0);
    }

  }
}
