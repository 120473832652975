.accordion {
  font-size: rem-calc(20);
  color: $light-text-color;
  margin-bottom: 0;
  .accordion-item {
    a.accordion-title {
      border: none;
      font-size: rem-calc(20);
      color: $light-text-color;
      line-height: rem-calc(38);
      &:hover {
        color: $primary-color;
        background: none;
      }
      &:active{
        background: none;
      }
      &:before {
        position: absolute;
        top: 50%;
        right: rem-calc(10);
        content: '';
        color: $primary-color;
        transform: translate(-50%, 0%) rotate(90deg);
        width: rem-calc(25);
        height: rem-calc(25);
        background-size: rem-calc(25);
        background-image: url('../img/iconfinder-icon.svg"');
        @include ba-transition();
      }
    }
    &.is-active > a.accordion-title {
      @include ba-transition();
      &:before {
        transform: translate(-50%, 0%) rotate(0deg);
        background-image: url('../img/iconfinder-icon-minus.svg"');
      }
    }
  }
  .accordion-content {
    border: none;
    color: $light-text-color;
    .accordion-content:last-child {
      background: none;
    }
    //a {
    //  color: $light-text-color;
    //  &:hover,
    //  &:focus {
    //    color: $primary-color;
    //    background: none;
    //  }
    //}
  }
  .accordion-title,
  .accordion-content {
    padding: rem-calc(0);
  }
}

:last-child > .accordion-content:last-child {
  border: none;
}


