
.banner {
  padding: 0;
  position: relative;
  min-height: 120px;
  background: 50% 50% no-repeat $banner-bg-color;
  background-size: cover;
  @include breakpoint(medium up) {
    height: 320px;
  }
  @include breakpoint(medium down) {
    height: 220px;

  }

  &:after {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    content: '';
    //background: linear-gradient(to right, #1FB657 10%, #032666 70%);
    //background: $banner-bg-color;
    z-index: 0;
    background-color: rgba(9,20,48,0.6);
    //opacity: 0.6;
  }

  &__url {
    display: inline-block;
    .date {
      color: $white;

    }
  }

  .caption {
    padding: rem-calc(50 15);
    width: $global-width;
    left: 50%;
    max-width: 790px;
    position: absolute;
    top: 55%;
    transform: translate(-50%, -50%);
    z-index: 1;
    @include breakpoint(medium only) {
      padding: 20px 0;
    }

    @include breakpoint(medium down) {
      padding: 20px 0;
      width: auto;
    }
    @include breakpoint(medium up) {
      h1, .like-h1 {
        font-size: rem-calc(60);
        text-transform: uppercase;
      }
    }

  }
}

.testimonial {
  background-color: $light-gray;
  padding: rem-calc(20 0);
  text-align: center;
  color: $main-text-color;
  q {
    text-transform: uppercase;
    font-size: rem-calc(25);
  }
  .author {
    font-size: rem-calc(12);
    font-weight: bold;
    margin-bottom: 0;
    &:before {
      content: '—';
    }
  }
}
