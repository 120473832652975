.pdf {
  /* Globals */

  body {
    text-align: center;
  }

  /*** Typography ***/
  a, h3 {
    color: $primary-color;
  }

  h3 {
    font-size: 1.3em;
    text-transform: uppercase;
  }

  h4 {
    font-size: 1.2em;
  }

  p, ul li, body {
    line-height: 1.3;
    font-size: 1em;
    font-weight: 300;
  }
  .italic {
    font-style: italic;
  }

  article {
    column-count: 2;
  }

  .new-page {
    page-break-before: always;
  }

  /*** End typography ***/

  /*** General **/

  .wrapper,
  .main,
  .footer {
    padding: 0.9375em;
    width: 100%;
    margin: 0 auto 0;
    max-width: 62.5rem;
    text-align: left;

  }
  .wrapper {
    margin-top: rem-calc(20);
    .logo-wrapper {
      padding: rem-calc(30);
      width: 350px;
      margin: 0 auto;
      background-color: white;
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .post-title {
    color: $primary-color;
    font-size: rem-calc(40);
  }

  .bottom-line {
    border-bottom: 1px solid $primary-color;
    padding-bottom: 30px;
  }

  .top-line-bold {
    border-top: 2px solid $primary-color;
  }

  /*** End General **/

  /*** Banner ***/
  .pdf-banner {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #F0F0F1;
  }

  .contacts {
    .year, .share, .job-title {
      margin-bottom: 0;
    }
    .job-title {
      font-size: rem-calc(18);
    }
    a {
      color: $black;
    }
    ul {
      margin-left: 0;
      padding-left: 0;
      margin-bottom: rem-calc(5);
      li {
        list-style-type: none;
      }
    }
    .social-icon {
      margin: rem-calc(5);
      font-size: rem-calc(12);
    }

  }

  .plain.related a {
    color: #000;
  }

  .sections .content {
    min-height: 50px;
    padding: 12px 0;
  }

  .sections .title {
    padding: 10px 0;

  }

  /*** End banner ***/

  /*** Main ***/
  .main-content {
    padding: rem-calc(40 0);
  }

  .person-image {
    float: right;
  }

  .job-title {
    color: $primary-color;
    text-transform: uppercase;
  }

  .name-title,
  .jobt-itle,
  .testimonial .quote p,
  .testimonial .source p {
    line-height: 1.0;
    margin: 0;
  }

  .job-title {
    font-size: 20px;

  }
  .profiles-sections {
    color: $light-text-color;
    &__item {
      padding: rem-calc(20 0);
      .title {
        color: $primary-color;
        font-size:1.2em;
      }
    }
  }

  /*** End Main ***/
  /*** Footer ***/
  .pdf-footer {
    .footer-content {
      border-top: 1px solid $primary-color;
      padding: rem-calc(10 0);
      font-size: rem-calc(14);
      text-align: center;
      p {
        margin-bottom: 0;
      }
    }
  }
  /*** End footer ***/
}