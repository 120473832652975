/* GF */
.join-mailing {

  @include breakpoint(medium up) {
    //.gform_body {
    //  ul.gform_fields {
    //    display: flex;
    //    align-items: flex-end;
    //  }
    //}
  }

  .gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium {
    width: calc(100% - 5px);
  }

  .gform_wrapper ul.gform_fields li.gfield {
    padding-right: rem-calc(5);
    width: 100%;
  }

  .gform_wrapper .gform_body {
    width: 100%;
  }

  .gform_wrapper.gform_wrapper .gform_footer input.button {
    background-color: $primary-color;
  }

  .gform_wrapper .gform_footer {
    width: auto;
    padding: 16px 0;
    margin: 0;
    @include breakpoint(small down) {
      max-width: 50%;
      .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
        line-height: 1.19;
      }
    }

  }
}

/* End GF */